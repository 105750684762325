import {
  Brightness1,
  CopyAllOutlined,
  LiveTvOutlined,
} from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { HiRefresh } from 'react-icons/hi';
import Swal from 'sweetalert2';

import Loading from '../../components/Loading';
import { IEvent } from '../../models/content';
import { startEvent, stopEvent } from '../../services/live';
import * as S from './styles';

type LiveEventTemplateProps = {
  event: IEvent;
  isLoading: boolean;
};

const DEFAULT_COPY_STATE = {
  urlStream: false,
  keyStream: false,
};

export default function LiveEventTemplate({
  event,
  isLoading,
}: LiveEventTemplateProps) {
  const [play, setPlay] = useState(false);
  const [copy, setCopy] = useState(DEFAULT_COPY_STATE);
  const [isPreview, setIsPreview] = useState(false);
  const [showIframe, setShowIframe] = useState(true);

  const urlStreamInput = useRef<HTMLInputElement>(null);
  const keyStreamInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (event.status === 'ACTIVE') {
      setPlay(true);
      setIsPreview(true);
    }
  }, [event]);

  const handleTransmission = () => {
    if (!isPreview) {
      return Swal.fire({
        icon: 'error',
        title: 'Você nao pode iniciar o evento',
        text: 'Por favor, inicialize a previzualização antes de iniciar o evento.',
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      });
    }
    if (event.status === 'STOPPED') {
      return Swal.fire({
        icon: 'error',
        title: 'Você nao pode iniciar o evento',
        text: 'Esse evento já foi finalizado, não será possível iniciar ele novamente.',
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      });
    }

    try {
      if (play) {
        Swal.fire({
          icon: 'question',
          title: 'Confirmação',
          html: 'Você tem certeza que deseja parar a transmissão? Isso não pode ser desfeito.',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sim, estou ciente',
          reverseButtons: true,
          focusConfirm: true,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
            cancelButton: 'btn-cancel',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.isConfirmed) {
            stopEvent(event.event_id)
              .then(() => setPlay(!play))
              .catch(error => {
                Swal.fire({
                  icon: 'error',
                  title: 'Erro',
                  text: `Erro ao ${play ? 'parar' : 'iniciar'} o evento: ${
                    error.response.data.message
                  }`,
                });
              });
          }
        });
      } else {
        Swal.fire({
          icon: 'question',
          title: 'Confirmação',
          html: "Você está visualizando a prévia da live do evento corretamente? Se sim, ao confirmar, o evento ficará disponível para os usuários. Caso contrário, clique em 'Cancelar' e atualize a prévia clicando no botão de 'Atualizar'. ",
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sim, estou ciente',
          reverseButtons: true,
          focusConfirm: true,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
            cancelButton: 'btn-cancel',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.isConfirmed) {
            startEvent(event.event_id).then(() => setPlay(!play));
          }
        });
      }
    } catch (error: any) {
      console.error(
        `Erro ao ${play ? 'parar' : 'iniciar'} o evento: `,
        error.message,
      );
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: `Erro ao ${play ? 'parar' : 'iniciar'} o evento: ${
          error.message
        }`,
      });
    }
  };

  const handleCopy = (inputRef: React.RefObject<HTMLInputElement>) => {
    const textToCopy = inputRef.current?.value;
    const inputName = inputRef.current?.name;

    if (!textToCopy || !inputName) return;

    navigator.clipboard
      .writeText(textToCopy)
      .then(function () {
        setCopy(state => ({ ...state, [inputName]: true }));

        setTimeout(() => {
          setCopy(state => ({ ...state, [inputName]: false }));
        }, 3000);
      })
      .catch(function (error) {
        console.error('Erro ao copiar: ', error);
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: `Erro ao copiar: ${error}`,
        });
      });
  };

  const reloadIframe = () => {
    setShowIframe(false); // Desmonta o iframe
    setTimeout(() => setShowIframe(true), 100); // Remonta o iframe após 100ms (pode ajustar o tempo se necessário)
  };

  if (isLoading) return <Loading />;

  return (
    <S.Wrapper>
      <S.Container>
        {isPreview ? (
          <S.PlayerContainer>
            {showIframe && (
              <iframe
                src={`${event.reference}?autoStart=true`}
                width="100%"
                height="100%"
              />
            )}
          </S.PlayerContainer>
        ) : (
          <S.PlayerContainer>
            <span>Pré-visualização do video</span>
            <span>Clique em iniciar pré-visualização para carregar...</span>
          </S.PlayerContainer>
        )}

        <S.WrapperBtns>
          <S.BtnTransmission onClick={handleTransmission} isPlay={play}>
            {play ? (
              <span>
                <Brightness1 /> Parar Transmissão
              </span>
            ) : (
              'Iniciar Tramissão'
            )}
          </S.BtnTransmission>
          <S.BtnPreview
            onClick={() => {
              isPreview ? reloadIframe() : setIsPreview(!isPreview);
            }}
            isPlay={isPreview}
          >
            {isPreview ? (
              <span>
                <HiRefresh size={19} />
                Atualizar pré-visualização
              </span>
            ) : (
              'Iniciar pré-visualização'
            )}
          </S.BtnPreview>
        </S.WrapperBtns>

        <S.Content>
          <div>
            <h1>Faça uma transmissão ao vivo com um software de streaming</h1>
            <p>
              Copie e cole a chave de stream em seu software de streaming. Essa
              chave de stream exclusiva informa ao seu software de streaming
              para onde enviar o feed do vídeo e permite que o Instagram o
              aceite.
            </p>
          </div>

          <S.Label isCopy={copy.urlStream}>
            URL do stream
            <div
              title="Clique para copiar a url do stream"
              onClick={() => handleCopy(urlStreamInput)}
            >
              <input
                type="text"
                name="urlStream"
                disabled
                ref={urlStreamInput}
                value={event.encoder_url}
              />
              <span>{copy.urlStream ? 'Copiado' : 'Copiar'}</span>
            </div>
          </S.Label>

          <S.Label isCopy={copy.keyStream}>
            Chave de stream
            <div
              title="Clique para copiar a chave de stream"
              onClick={() => handleCopy(keyStreamInput)}
            >
              <input
                type="text"
                name="keyStream"
                disabled
                ref={keyStreamInput}
                value={event.transmission_key}
              />
              <span>{copy.keyStream ? 'Copiado' : 'Copiar'}</span>
            </div>
          </S.Label>

          <div>
            <h2>Como transmitir</h2>

            <S.Line>
              <CopyAllOutlined />
              <span>
                Copie a chave de stream e insira-a no software de streaming.
              </span>
            </S.Line>

            <S.Line>
              <LiveTvOutlined />
              <span>
                Selecione Iniciar trasmissão assim que seu software de streaming
                se conectar e aparecer como uma prévia.
              </span>
            </S.Line>
          </div>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
}
