import { SearchOutlined } from '@mui/icons-material';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useGetContent, useTablePagination } from '../../../hooks';
import { IMinimalUser } from '../../../models/user';
import { listUsers } from '../../../services/users';
import { formatCnpj, formatCpf, removeTraitPoints } from '../../../utils';
import { handleSort } from '../../../utils/handleSort';
import Button from '../../Button';
import CustomSelect from '../../CustomSelect';
import { FlexContainer } from '../../FlexContainer';
import Table, { TableProps } from '../../Table';
import SimpleModal from '..';
import RegisterUserModal from '../RegisterUserModal';
import * as S from './styles';

type UserModalProps = {
  isOpen: boolean;
  classUser: string[] | [];
  onCloseChange: () => void;
  handleSelected: (users) => void;
};
type OrderBySortType = 'ASC' | 'DESC';
type OrderByType = 'name' | 'created_at' | 'last_login';

const optionsToSelect = [
  { label: 'Nome', value: 'search' },
  { label: 'CPF', value: 'cpf' },
  { label: 'CNPJ', value: 'cnpj' },
  { label: 'Nome da empresa', value: 'search_company' },
];

export default function UserModal({
  classUser,
  isOpen,
  handleSelected,
  onCloseChange,
}: UserModalProps) {
  const [rows, setRows] = useState<IMinimalUser[] | []>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowId[] | []>([]);
  const [orderBySort, setOrderBySort] = useState<OrderBySortType>('ASC');
  const [orderBy, setOrderBy] = useState<OrderByType>('name');
  const [selectedFilter, setSelectedFilter] = useState<{
    label: string;
    value: string;
  }>(optionsToSelect[0]);
  const [openModal, setOpenModal] = useState(false);
  const [haveNewUser, setHaveNewUser] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const { loading, getVariedContents } = useGetContent(listUsers);
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();

  const columns = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'name',
        minWidth: 200,
        flex: 2,
        valueGetter: ({ row }) => row.name,
      },
      {
        headerName: 'CPF',
        field: 'cpf',
        minWidth: 100,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => formatCpf(row.cpf),
      },
      {
        headerName: 'E-mail',
        field: 'email',
        minWidth: 200,
        flex: 2,
        sortable: false,
        valueGetter: ({ row }) => row.email,
      },
    ],
    [selectedRows],
  );

  const params = {
    limit: paginationModel.pageSize,
    offset: paginationModel.page,
    order_by: orderBy,
    order_by_sort: orderBySort,
  };

  useEffect(() => {
    getVariedContents(setRows, setRowCount, params);

    if (haveNewUser) setHaveNewUser(false);
  }, [
    paginationModel.pageSize,
    paginationModel.page,
    orderBy,
    orderBySort,
    haveNewUser,
  ]);

  useEffect(() => {
    if (classUser.length) {
      setSelectedRows(classUser);
    }
  }, [rows]);

  const handleSelectUser = (userIds: GridSelectionModel) => {
    const users = rows.filter(row => userIds.includes(row.user_id));
    handleSelected(users);
  };

  const onPageChange = (page: number) => {
    changePage(page);
  };

  const onPageSizeChange = (pageSize: number) => {
    changePageSize(pageSize);
  };

  const tableProps: TableProps = {
    columns,
    rows,
    keyId: 'user_id',
    onPageChange,
    onPageSizeChange,
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSelectionModelChange: handleSelectUser,
    selectionModel: classUser,
    sortingMode: 'server',
    onSortModelChange: sortModel =>
      handleSort({
        changePage,
        setOrderBy,
        setOrderBySort,
        sortModel,
        letterConverter: 'toLowerCase',
      }),
    disableColumnMenu: true,
    initialState: {
      sorting: {
        sortModel: [{ field: 'name', sort: 'asc' }],
      },
    },
    keepNonExistentRowsSelected: true,
  };

  const applyMask = value => {
    console.log(value);
    if (selectedFilter.value === 'cpf') {
      return formatCpf(value);
    } else if (selectedFilter.value === 'cnpj') {
      return formatCnpj(value);
    } else {
      return value;
    }
  };

  const handleMaskInput = () => {
    if (!inputRef.current) return;

    const inputValue = inputRef.current.value;
    inputRef.current.value = applyMask(inputValue);
  };

  const handleSubmit = () => {
    const searchInput = inputRef.current?.value;
    const searchParams = params;

    if (searchInput !== undefined && searchInput.length) {
      const filter = selectedFilter?.value;
      searchParams[filter] = handleMask[filter](searchInput);
    }

    getVariedContents(setRows, setRowCount, searchParams);
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  function CustomToolbar() {
    return (
      <S.ToolBarWrapper>
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          Criar usuário
        </Button>
      </S.ToolBarWrapper>
    );
  }

  const components = {
    Toolbar: CustomToolbar,
  };

  const handleMask = {
    cpf: (cpf: string) => removeTraitPoints(cpf),
    search: (name: string) => name,
    cnpj: (cnpj: string) => removeTraitPoints(cnpj),
    search_company: (searchCompany: string) => searchCompany,
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onCloseChange={onCloseChange}
      title="Lista de Alunos"
    >
      <S.Wrapper>
        <FlexContainer gap="0.875rem">
          <CustomSelect
            id="filter"
            isRtl={false}
            isSearchable
            options={optionsToSelect}
            onChange={value => {
              if (inputRef.current) inputRef.current.value = '';
              if (value) setSelectedFilter(value);
            }}
            value={selectedFilter}
            thereIsError={false}
            marginTop="0.875rem"
          />

          <S.Input
            id="search"
            ref={inputRef}
            placeholder={`Busque pelo ${selectedFilter?.label.toLocaleLowerCase()}`}
            onChange={handleMaskInput}
            onKeyDown={handlePressEnter}
          />

          <S.SearchButton
            type="button"
            title="Fazer a busca pelo nome do usuário"
            onClick={handleSubmit}
          >
            <SearchOutlined fontSize="inherit" />
          </S.SearchButton>
        </FlexContainer>

        <Table
          {...tableProps}
          components={components}
          width="90vw"
          height="100%"
          checkboxSelection
          disableColumnMenu
        />
      </S.Wrapper>

      {openModal &&
        createPortal(
          <RegisterUserModal
            isOpen={openModal}
            onCloseChange={() => setOpenModal(false)}
            resgisteredNewUser={() => setHaveNewUser(true)}
          />,
          document.body,
        )}
    </SimpleModal>
  );
}
